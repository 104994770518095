import { lazyLoadAnalytics } from '../../../../Common/Resources/src_front/js/components/lazyLoadAnalytics';
import { gtm } from './сomponents/analytics/gtm';
import { pushengage } from './сomponents/analytics/pushengage';
import { initAmplitude } from './сomponents/initAmplitude';

document.addEventListener('DOMContentLoaded', function () {
    lazyLoadAnalytics(gtm);
    lazyLoadAnalytics(pushengage);
    initAmplitude();
});
